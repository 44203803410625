import React, { useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';
import Pin from './Pin';
import { v4 as uuidv4 } from 'uuid'

import Spinner from './Spinner';
import {API, PSearch} from '../utils/constants';
import Paginate from './Paginate';
import Adds from './Adds';
import Headtag from './Headtag';

const breakpointColumnsObj = {
  default: 4,
  3000: 6,
  2000: 5,
  1200: 3,
  1000: 2,
  500: 1,
};

const Search = ({ searchTerm, page, setPage }) => {
    const [pins, setPins] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    
    const fetchImages = (pp) => {
      try {
        setLoading(true)
        const graphqlQuery = {
          query: PSearch,
          variables: {
            key: searchTerm,
            page: pp,
          },
        };
        
        fetch(API, {
          method: "POST",
          headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
          },
          body: JSON.stringify(graphqlQuery),
        })
          .then(async (result) => {
            const res = await result.json();
            return res;
          })
          .then((response) => {
            let res = response;
              setPins(res.data.Search.data);
              setHasMore(res.data.Search.paginatorInfo.hasMorePages)
              setLoading(false);
             
          
          })
          .catch((error) => {
            console.log(error)
          })
       
          
      } catch {
        setLoading(false)    
      }
     
      
    };
  
  
    useEffect(() => {
      fetchImages(page);
     
    }, [searchTerm,page]);
  
  

  return (
    <div>
      <Headtag
        title={ searchTerm || "Search..."}
        description="Download Free vector Art For Cnc And Laser Cut Shape And Pattern Decoration  - free design vector ( eps . cdr . dxf . pdf ), vector laser, vector art, vector graphic , file corel, file illustrator, file design, vector design , free dxf files with cuttable designs cut ready for cnc machines plasma,laser, router, waterjet. diy for wall art, home decor,3d, sign, silhouette, 3d puzzle, plasma cutter, wood cutting and engraving, cnc, file,"
        keywords="free design vector ( eps . cdr . dxf . pdf ), vector laser, vector art, vector graphic , file corel, file illustrator, file design, vector design , free dxf files with cuttable designs cut ready for cnc machines plasma,laser, router, waterjet. diy for wall art, home decor,3d, sign, silhouette, 3d puzzle, plasma cutter, wood cutting and engraving, cnc, file,"
     
      /> 

      {loading && <Spinner message="Searching pins" />}
     
       <Masonry className="flex animate-slide-fwd" breakpointCols={breakpointColumnsObj}>
       {pins?.map((pin, i) =>{
        /* if(i%8===1){
          return (
           <><Adds /></>
       );
     } */
         return(<Pin key={uuidv4()} pin={pin} className="w-max" />)})}
     </Masonry>
     <Paginate page={page} setPage={setPage} hasMore={hasMore} /> 

      {pins?.length === 0 && searchTerm !== '' && !loading && (
        <div className="mt-10 text-center text-xl ">No Pins Found!</div>
      )}
    </div>
  );
};

export default Search;
