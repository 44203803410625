
import React, { useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import Spinner from './Spinner';
import {API, createPost, createCategory} from '../utils/constants';
import { loadCats } from "../redux/apiCalls";
import { useDispatch,useSelector } from 'react-redux';

const CreatePin = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [about, setAbout] = useState('');
  const [loading, setLoading] = useState(false);
  const [destination, setDestination] = useState();
  const [fields, setFields] = useState(false);
  const [category, setCategory] = useState();
  const [imageAsset, setImageAsset] = useState();
  const [wrongImageType, setWrongImageType] = useState(false);
  const [img, setImg] = useState([]);
  const [file, setFile] = useState([]);
  const [catName, setCatName] = useState([]);
  const user = useSelector((state) => state.user.userInfo);
  const categories = useSelector((state) => state.Categories.cats);
  const reader = new FileReader()
  
  
  const navigate = useNavigate();
  const createCat = ()=>{
  if (catName ) {
    

    try {
      setLoading(true)
      const author_id = parseInt(user.id)
      const graphqlQuery = {
        query: createCategory,
        variables: {
          name: catName,
          author_id: author_id,
        },
      };
      
      fetch(API, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.api_token,
      "Content-Type": "application/json",
      Accept: "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response;
          loadCats(dispatch);
         setLoading(false)  
        
        })
        .catch((error) => {
          console.log(error)
        })
     
        
    } catch {
      setLoading(false)    
    }
    
    
  }else{
    setFields(true);
      setTimeout(
        () => {
          setFields(false);
        },
        2000,
      );
    }
}
 
 const create = ()=>{
  if (title && about && category ) {
    

    try {
      setLoading(true)
      const author_id = parseInt(user.id)
      const graphqlQuery = {
        query: createPost,
        variables: {
          title: title,
          content: about,
          category_id: category,
          author_id: author_id,
        },
      };
      
      fetch(API, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.api_token,
      "Content-Type": "application/json",
      Accept: "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response;
          filesPost(img,file,data.data.createPost.id) 
        
        })
        .catch((error) => {
          console.log(error)
        })
     
        
    } catch {
      setLoading(false)    
    }
    
    
  }else{
    setFields(true);
      setTimeout(
        () => {
          setFields(false);
        },
        2000,
      );
    }
  }
 
  const filesPost= async (img, file,id) => {
  
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + user.api_token);
    
    var formdata = new FormData();
    const operations = { query: `
    mutation uploadFiles($img:Upload!, $file:Upload!) {
      uploadFiles(
        image: $img file: $file id: ${id}
      )
    }
  `
  } ;
    const map = { 
      "0": ["variables.img"],
      "1": ["variables.file"],
    };
    formdata.append("operations", JSON.stringify(operations))
  
    formdata.append("map", JSON.stringify(map));
   
    formdata.append("0", img);
    formdata.append("1", file);
    
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    

    
    fetch(API, requestOptions)
      .then(response => response.text())
      .then((result) => {
       
        setLoading(false)
    })
      .catch((error) => { 
        setLoading(false)
    });
  

  };
  
  
  return (
    <div>
    <div className="flex flex-col justify-center items-center mt-5 lg:h-4/5">
     

      {fields && (
        <p className="text-red-500 mb-5 text-xl transition-all duration-150 ease-in ">Please add all fields.</p>
      )}
      {loading ?  <Spinner />
     :
      <div className=" flex lg:flex-row flex-col justify-center items-center bg-white lg:p-5 p-3 lg:w-4/5  w-full">
        <div className="bg-yellow p-3 flex flex-0.7 w-full">
          <div className=" flex justify-center items-center flex-col border-2 border-dotted border-gray-300 p-3 w-full h-420">
            
            {
              wrongImageType && (
                <p>It&apos;s wrong file type.</p>
              )
            }
            {!imageAsset ? (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label>
                <div className="flex flex-col items-center justify-center h-full">
                  <div className="flex flex-col justify-center items-center">
                    <p className="font-bold text-2xl">
                      <AiOutlineCloudUpload />
                    </p>
                    <p className="text-lg">Click to upload</p>
                  </div>

                  <p className="mt-32 text-gray-400">
                    Recommendation: Use high-quality JPG, JPEG, SVG, PNG, GIF or TIFF less than 20MB
                  </p>
                </div>
                <input
                  type="file"
                  name="upload-image"
                  onChange={(e)=>{setImg(e.target.files[0]);
                  setImageAsset(URL.createObjectURL(e.target.files[0]))
                  }}
                  className="w-0 h-0"
                />
              </label>
            ) : (
              <div className="relative h-full">
                <img
                  src={imageAsset}
                  alt="uploaded-pic"
                  className="h-full w-full"
                />
                <button
                  type="button"
                  className="absolute bottom-3 right-3 p-3 rounded-full bg-white text-xl cursor-pointer outline-none hover:shadow-md transition-all duration-500 ease-in-out"
                  onClick={() => setImageAsset(null)}
                >
                  <MdDelete />
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-1 flex-col gap-6 lg:pl-5 mt-5 w-full">
          <h1 className="outline-none text-2xl sm:text-3xl font-bold border-b-2 border-gray-200 p-2 text-center"> Create Post </h1>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Add your title"
            className="outline-none text-2xl sm:text-3xl font-bold border-b-2 border-gray-200 p-2"
          />
          {user && (
            <div className="flex gap-2 mt-2 mb-2 items-center bg-white rounded-lg ">
              <img
                src={user.uimg}
                className="w-10 h-10 rounded-full"
                alt="user-profile"
              />
              <p className="font-bold">{user.name}</p>
            </div>
          )}
          <input
            type="text"
            value={about}
            onChange={(e) => setAbout(e.target.value)}
            placeholder="Tell everyone what your Pin is about"
            className="outline-none text-base sm:text-lg border-b-2 border-gray-200 p-2"
          />
          <div className="flex flex-col">
            <div>
              <p className="mb-2 font-semibold text:lg sm:text-xl">Choose Pin Category</p>
              <select
                onChange={(e) => {
                  setCategory(parseInt(e.target.value));
                }}
                className="outline-none w-4/5 text-base border-b-2 border-gray-200 p-2 rounded-md cursor-pointer"
              >
                <option value="others" className="sm:text-bg bg-white">Select Category</option>
                {categories.map((item) => (
                  <option className="text-base border-0 outline-none capitalize bg-white text-black " value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
             <div>
             <label for="formFileSm" class="form-label inline-block mb-2 text-gray-700">Add File</label>
              <input
                type="file"
                vlaue={destination}
                onChange={(e)=>setFile(e.target.files[0])}
                placeholder="Add a destination link"
                className="outline-none block
                w-full
                px-2
                py-1
                text-sm
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border-b-2 border-gray-200
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              />
            </div> 
           
            <div className="flex justify-end items-end mt-5">
              <button
                type="button"
                onClick={create}
                className="bg-yellow-500 text-white font-bold p-2 rounded-full w-28 outline-none"
              >
                Save Pin
              </button>
            </div>
          </div>
        </div>
      </div>  }
    </div>
    <div className="flex flex-col justify-center items-center mt-5 lg:h-4/5">
     

    {fields && (
      <p className="text-red-500 mb-5 text-xl transition-all duration-150 ease-in ">Please add all fields.</p>
    )}
    {loading ?  <Spinner />
   :
    <div className=" flex lg:flex-row flex-col justify-center items-center bg-white lg:p-5 p-3 lg:w-4/5  w-full">
      
      <div className="flex flex-1 flex-col gap-6 lg:pl-5 mt-5 w-full">
      <h1 className="outline-none text-2xl sm:text-3xl font-bold border-b-2 border-gray-200 p-2 text-center"> Create Category </h1>
         
        <input
          type="text"
          value={catName}
          onChange={(e) => setCatName(e.target.value)}
          placeholder="Add Category Name"
          className="outline-none text-2xl sm:text-3xl font-bold border-b-2 border-gray-200 p-2"
        />
        {user && (
          <div className="flex gap-2 mt-2 mb-2 items-center bg-white rounded-lg ">
            <img
              src={user.uimg}
              className="w-10 h-10 rounded-full"
              alt="user-profile"
            />
            <p className="font-bold">{user.name}</p>
          </div>
        )}
       
        <div className="flex flex-col">
         
         
          <div className="flex justify-end items-end mt-5">
            <button
              type="button"
              onClick={createCat}
              className="bg-yellow-500 text-white font-bold p-2 rounded-full w-28 outline-none"
            >
              Save Categorie
            </button>
          </div>
        </div>
      </div>
    </div>  }
  </div>
  </div>
  );

};

export default CreatePin;
