import React, { useEffect, useRef, useState } from 'react';
import { MdDownloadForOffline } from 'react-icons/md';
import ReCAPTCHA from "react-google-recaptcha";
import MasonryLayout from './MasonryLayout'
import { Link, useParams } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import { FcGoogle } from 'react-icons/fc';
import { v4 as uuidv4 } from 'uuid';
import { API,Storage, Post,createcomment, downloadpost } from '../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from "file-saver";
import Spinner from './Spinner';
import Headtag from './Headtag'
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import { updateUser } from "../redux/apiCalls";


const PinDetail = () => {
  const { pinId } = useParams();
  const [pins, setPins] = useState([]);
  const [captcha, setCaptcha] = useState(null);
  const [pinDetail, setPinDetail] = useState();
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [addingComment, setAddingComment] = useState(false);
  const topRef = useRef(null);
  const user = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();
 
  function getRandom(arr, n) {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
    
}

  function onChange(value) {
    setCaptcha(value)
  }

  const fetchPinDetails = (id) => {
    try {
      setLoading(true)
      const graphqlQuery = {
        query: Post,
        variables: {
          id: id,
        },
      };
      
      fetch(API, {
        method: "POST",
        headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let res = response;
            setPinDetail(res.data.post);
            setPins(res.data.post.category.posts)
            setLoading(false);
        
        })
        .catch((error) => {
          console.log(error)
        })
     
        
    } catch {
      setLoading(false)    
    }
    };

    const downloadPin = ()=>{
      try {
         
        const graphqlQuery = {
          query: downloadpost,
          variables: {
            author_id:parseInt(user.id) || 1,
            post_id:parseInt(pinId),
          },
        };
        
        fetch(API, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + user.cncToken,
        "Content-Type": "application/json",
        Accept: "application/json",
          },
          body: JSON.stringify(graphqlQuery),
        })
          .then(async (result) => {
            const res = await result.json();
            return res;
          })
          .then((response) => {
            
          
          })
          .catch((error) => {
            console.log(error)
          })
       
          
      } catch {
        
      }
     };
     
     const responseGoogle = (response) => {
      const mt = sessionStorage.setItem('cncstorage', JSON.stringify(response.profileObj));
      const user= sessionStorage.getItem('cncstorage', JSON.stringify(response.profileObj));
     updateUser( JSON.parse(user), dispatch );
      
    };
  useEffect(() => {
    
  
    fetchPinDetails(pinId);
    
  }, [pinId]);

  const Comment = () => {
    try {
      setAddingComment(true)
      const graphqlQuery = {
        query: createcomment,
        variables: {
          reply: comment,
          author_id: parseInt(user.id),
          post_id: parseInt(pinId), 
        },
      };
      
      fetch(API, {
        method: "POST",
        headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let res = response;
            setAddingComment(false);
            window.location.reload();
        
        })
        .catch((error) => {
          console.log(error)
        })
     
        
    } catch {
      setLoading(false)    
    }
  };

  if (!pinDetail) {
    return (
      <Spinner message="Showing pin" />
    );
  }
const cookiesData = Cookies.get('D');
  return (
    <>
    
      {pinDetail && (
       <>  <Headtag
        title={pinDetail.title + "vector free download AND cnc files free download" || "vector free download AND cnc files free download"}
        description={pinDetail.content + "vector free download AND cnc files free download" || "vector free download AND cnc files free download"}
        keywords={"cnc vector,cnc decoration,file cncdxf cnc,cnc designs,cnc dxf,3 axis cnc dxf,arabic cnc,cnc design,cnc dxf files download,cnc file dxf,cncfile"}
        imageUrl={Storage+pinDetail.img.replace('public','')}
        imageAlt={pinDetail.title +"vector free download AND cnc files free download" || "vector free download AND cnc files free download"}
      /> 
        <div className="flex xl:flex-row flex-col m-auto bg-white" style={{ maxWidth: '1500px', borderRadius: '32px' }} ref={topRef}>
          <div className="flex justify-center items-center md:items-start flex-initial">
            <img
              className="rounded-t-3xl rounded-b-lg"
              src={Storage+pinDetail.img.replace('public','')}
              alt={pinDetail.title}
            />
          </div>
          <div className="w-full p-5 flex-1 xl:min-w-620">
            {cookiesData >= 4 && !user ? <div className="shadow-2xl ">
            <GoogleLogin
              clientId={`${process.env.REACT_APP_GOOGLE_API_TOKEN}`}
              render={(renderProps) => (
                <button
                  type="button"
                  className="h-12 bg-mainColor flex justify-center items-center p-3 rounded-lg cursor-pointer outline-none hover:bg-yellow-300"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <FcGoogle className="mr-4" /><h2 className="font-bold">login</h2> 
                </button>
              )}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy="single_host_origin"
            />
          </div > : <div className="flex items-center justify-between">
              <div className="flex gap-2 items-center">
              {!captcha  ? <ReCAPTCHA
    sitekey="6LepAAQfAAAAAEQqPpVQFPcWkJ7iy0Q35WqhDz1_"
    onChange={onChange}
  />:
                <button
                  
                  onClick={(e)=>{saveAs(
                    Storage+pinDetail.file.replace('public',''),
                    "cncmotifs.zip"
                  ); downloadPin(); cookiesData ? Cookies.set('D', parseInt(cookiesData) + 1, { expires: 1 }): Cookies.set('D', 1, { expires: 1 })}}
                  className="bg-yellow-500 w-32 h-auto p-2 text-xl rounded-3xl flex items-center justify-center text-dark opacity-75 hover:opacity-100"
                  
                >
                  <MdDownloadForOffline />
                </button>}
              </div>
              <a href={pinDetail.destination} target="_blank" rel="noreferrer">
                {pinDetail.destination?.slice(8, 17)}
              </a>
            </div>}
            <div>
              <h1 className="text-4xl font-bold break-words mt-3">
                {pinDetail.title}
              </h1>
              <p className="mt-3">{pinDetail.content}</p>
            </div>
            <Link to={`/user-profile/${pinDetail?.author.id}`} className="flex gap-2 mt-5 items-center bg-white rounded-lg ">
              <img src={pinDetail?.author.uimg} className="w-10 h-10 rounded-full" alt="user-profile" />
              <p className="font-bold">{pinDetail?.author.name}</p>
            </Link>
            <h2 className="mt-5 text-2xl">Comments</h2>
            <div className="max-h-370 overflow-y-auto">
              {pinDetail?.comments?.map((item) => (
                <div className="flex gap-2 mt-5 items-center bg-white rounded-lg" key={item.comment}>
                  <img
                    src={item.user?.uimg}
                    className="w-10 h-10 rounded-full cursor-pointer"
                    alt="user-profile"
                  />
                  <div className="flex flex-col">
                    <p className="font-bold">{item.user?.name}</p>
                    <p>{item.reply}</p>
                  </div>
                </div>
              ))}
            </div>
            {user ? <div className="flex flex-wrap mt-6 gap-3">
              <Link to={`/user-profile/${pinDetail.author.id}`}>
                <img src={pinDetail.author.uimg} className="w-10 h-10 rounded-full cursor-pointer" alt="user-profile" />
              </Link>
              <input
                className=" flex-1 border-gray-100 outline-none border-2 p-2 rounded-2xl focus:border-gray-300"
                type="text"
                placeholder="Add a comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <button
                type="button"
                className="bg-yellow-500 text-white rounded-full px-6 py-2 font-semibold text-base outline-none"
                onClick={Comment}
              >
                {addingComment ? 'Doing...' : 'Done'}
              </button>
            </div> : <div></div>}
          </div>
        </div></>
      )}
      {pins?.length > 0 && (
        <h2 className="text-center font-bold text-2xl mt-8 mb-4">
          More like this
        </h2>
      )}
      {pins ? (
        <MasonryLayout pins={pins} />
      ) : (
        <Spinner message="Loading more pins" />
      )}
      
    </>
  );
};

export default PinDetail;
