import React, { useEffect, useState } from 'react';
import { AiOutlineLogout } from 'react-icons/ai';
import { useParams, useNavigate } from 'react-router-dom';
import { GoogleLogout } from 'react-google-login';
import { API,Storage, profile } from '../utils/constants';
import { userCreatedPinsQuery, userQuery, userSavedPinsQuery } from '../utils/data';
import MasonryLayout from './MasonryLayout';
import Spinner from './Spinner';
import Headtag from './Headtag';

const activeBtnStyles = 'bg-yellow-500 text-white font-bold p-2 rounded-full w-20 outline-none';
const notActiveBtnStyles = 'bg-primary mr-4 text-black font-bold p-2 rounded-full w-20 outline-none';

const UserProfile = () => {
  const [userDetail, setUserDetail] = useState();
  const [pins, setPins] = useState([]);
  const [created, setCreated] = useState([]);
  const [saved, setSaved] = useState({});
  const [text, setText] = useState('Created');
  const [activeBtn, setActiveBtn] = useState('created');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { userId } = useParams();

  const getuser = (id)=>{
    try {
    setLoading(true)
    const graphqlQuery = {
      query: profile,
      variables: {
        id: userId,
      },
    };
    
    fetch(API, {
      method: "POST",
      headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
      },
      body: JSON.stringify(graphqlQuery),
    })
      .then(async (result) => {
        const res = await result.json();
        return res;
      })
      .then((response) => {
        let res = response;
        
          setUserDetail(res.data.user);
          setPins(res.data.user.posts)
          setCreated(res.data.user.posts)
          const tt = res.data.user.pined.map((pin,i) => pin.post)
          setSaved(tt)
          setLoading(false);
      
      })
      .catch((error) => {
        console.log(error)
      })
   
      
  } catch {
    setLoading(false)    
  }}
  useEffect(() => {
    getuser(userId)
  }, [userId]);

  useEffect(() => {
    if (text === 'Created') {
      setPins(created)
    } else {
      
      setPins(saved);
    }
  }, [text, userId]);

  const logout = () => {
    localStorage.clear();

    navigate('/login');
  };

  if (!userDetail) return <Spinner message="Loading profile" />;

  return (
    <>
    <Headtag
        title={userDetail.name || "Motif Detail"}
        description={ "motifs creator"}
        keywords={".pdf, .xdf"}
      />
    <div className="relative pb-2 h-full justify-center items-center">
      <div className="flex flex-col pb-5">
        <div className="relative flex flex-col mb-7">
          <div className="flex flex-col justify-center items-center">
            <img
              className=" w-full h-370 2xl:h-510 shadow-lg object-cover"
              src="https://source.unsplash.com/1600x900/?nature,photography,technology"
              alt="user-pic"
            />
            <img
              className="rounded-full w-20 h-20 -mt-10 shadow-xl object-cover"
              src={userDetail.uimg}
              alt="user-pic"
            />
          </div>
          <h1 className="font-bold text-3xl text-center mt-3">
            {userDetail.name}
          </h1>
          <div className="absolute top-0 z-1 right-0 p-2">
            {userId === userDetail.id && (
              <GoogleLogout
                clientId={`${process.env.REACT_APP_GOOGLE_API_TOKEN}`}
                render={(renderProps) => (
                  <button
                    type="button"
                    className=" bg-white p-2 rounded-full cursor-pointer outline-none shadow-md"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    <AiOutlineLogout color="red" fontSize={21} />
                  </button>
                )}
                onLogoutSuccess={logout}
                cookiePolicy="single_host_origin"
              />
            )}
          </div>
        </div>
        <div className="text-center mb-7">
          <button
            type="button"
            onClick={(e) => {
              setText(e.target.textContent);
              setActiveBtn('created');
            }}
            className={`${activeBtn === 'created' ? activeBtnStyles : notActiveBtnStyles}`}
          >
            Created
          </button>
          <button
            type="button"
            onClick={(e) => {
              setText(e.target.textContent);
              setActiveBtn('saved');
            }}
            className={`${activeBtn === 'saved' ? activeBtnStyles : notActiveBtnStyles}`}
          >
            Saved
          </button>
        </div>

        <div className="px-2">
          <MasonryLayout pins={pins} saved={saved}/>
        </div>

        {pins?.length === 0 && (
        <div className="flex justify-center font-bold items-center w-full text-1xl mt-2">
          No Pins Found!
        </div>
        )}
      </div>

    </div>
    </>
  );
};

export default UserProfile;
