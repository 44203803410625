import React, { useState, useRef, useEffect } from 'react';
import GoogleLogin from 'react-google-login';
import { HiMenu } from 'react-icons/hi';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Link, NavLink, Route, Routes } from 'react-router-dom';
import { Sidebar, UserProfile } from '../components';
import { FcGoogle } from 'react-icons/fc';
import Pins from './Pins';
import logo from '../assets/logo.png';
import { updateUser } from '../redux/apiCalls';
import { useDispatch, useSelector } from 'react-redux';
import Headtag from '../components/Headtag';
import CookieConsent from "react-cookie-consent";
import Footer from '../components/Footer';

const Home = () => {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userInfo);
  const categories = useSelector((state) => state.Categories.cats);
  const scrollRef = useRef(null);
  useEffect(() => {
    scrollRef.current.scrollTo(0, 0);
  });
  const responseGoogle = (response) => {
     let user= JSON.stringify(response.profileObj);
     updateUser( JSON.parse(user), dispatch );
    
  };
  return (
    <>
    <Headtag
        title="CNCMOTIFS vector free download AND cnc files free download"
        description="Download Free vector Art For Cnc And Laser Cut Shape And Pattern Decoration  - free design vector ( eps . cdr . dxf . pdf ), cnc vector,cnc decoration,file cncdxf cnc,cnc designs,cnc dxf,3 axis cnc dxf,arabic cnc,cnc design,cnc dxf files download,cnc file dxf,cncfile"
        keywords="free design vector ( eps . cdr . dxf . pdf ),cnc vector,cnc decoration,file cncdxf cnc,cnc designs,cnc dxf,3 axis cnc dxf,arabic cnc,cnc design,cnc dxf files download,cnc file dxf,cncfile"
        imageUrl={logo}
        imageAlt="CNCMOTIFS vector free download AND cnc files free download"
      />
   
    <div className="flex bg-gray-50 md:flex-row flex-col h-screen transition-height duration-75 ease-out">
      <div className="hidden md:flex h-screen flex-initial">
        <Sidebar categories={categories} />
       
      </div>
      <div className="flex md:hidden flex-row">
        <div className="p-2 w-full flex flex-row justify-between items-center shadow-md">
          <HiMenu fontSize={40} className="cursor-pointer" onClick={() => setToggleSidebar(true)} />
          <Link to="/">
            <img src={logo} alt="logo" className="w-28" />
          </Link>
          {!user? <div className="shadow-2xl">
            <GoogleLogin
              clientId={`${process.env.REACT_APP_GOOGLE_API_TOKEN}`}
              render={(renderProps) => (
                <button
                  type="button"
                  className="bg-mainColor flex justify-center items-center p-3 rounded-lg cursor-pointer outline-none hover:bg-yellow-300"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <FcGoogle className="mr-4" /> Sign in with google
                </button>
              )}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy="single_host_origin"
            />
          </div> : <Link to={`user-profile/${user?.id}`}>
            <img src={user.uimg} alt="user-pic" className="w-9 h-9 rounded-full " />
          </Link>}
          
        </div>
        {toggleSidebar && (
        <div className="fixed w-4/5 bg-white h-screen overflow-y-auto shadow-md z-10 animate-slide-in">
          <div className="absolute w-full flex justify-end items-center p-2">
            <AiFillCloseCircle fontSize={30} className="cursor-pointer" onClick={() => setToggleSidebar(false)} />
          </div>
          <Sidebar closeToggle={setToggleSidebar} user={user } />
        </div>
        )}
      </div>
      <div className="pb-2 flex-1 h-screen overflow-y-scroll" ref={scrollRef}>
      

        <Routes>
          <Route path="/user-profile/:userId" element={<UserProfile user={user}/>} />
         
          <Route path="/*" element={<Pins />} />
          
        </Routes>
      </div>
    </div>
    <CookieConsent
  location="bottom"
  buttonText="Sure man!!"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={150}
>
  This website uses cookies to enhance the user experience.{" "}
  <span style={{ fontSize: "10px" }}>For more details read our <NavLink
      to="/Privacy"
    >
     Privacy & Cookie Policy
    </NavLink> </span>
</CookieConsent>
<Footer />
    </>
  );
};


export default Home;
