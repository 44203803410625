import React from 'react'
import { Helmet } from 'react-helmet'
import meta from '../utils/meta.json'

const Headtag = ({title,description,keywords,imageUrl,imageAlt,twitterUsername}) => {
  return (
    <Helmet>
    <meta charSet="utf-8" />    
    <title>{title}</title>
    <meta property="og:title" content={title} />
    <meta name="description" content={description} />
    <meta property="og:description" content={description} />
    <meta name="keywords" content={keywords} />
    <meta property="og:image" content={ imageUrl} />
    <meta
      property="og:url"
      content={"https://cncmotifs.com" + window.location.pathname + window.location.search}
    />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:image:alt" content={imageAlt} />
    <meta name="twitter:site" content={meta.twitterUsername} />
      </Helmet>
  )
}

export default Headtag