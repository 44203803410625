import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { MdDownloadForOffline } from 'react-icons/md';
import { AiTwotoneDelete } from 'react-icons/ai';
import { BsFillArrowUpRightCircleFill } from 'react-icons/bs';
import {API,Storage , removePost,savepost, removePined} from '../utils/constants';
import { useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import  Pinholder from '../assets/pino.svg'

const Pin = ({saved, pin, key, goToTop}) => {
  const [postHovered, setPostHovered] = useState(false);
  const [savingPost, setSavingPost] = useState(false);
  const navigate = useNavigate();
  const [alreadySaved, setAlreadySaved] = useState(false);
  const user = useSelector((state) => state.user.userInfo);
  

  const deletePin = (id) => {
    try {
     
      const graphqlQuery = {
        query: removePost,
        variables: {
          id: id,
        },
      };
      
      fetch(API, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.api_token,
      "Content-Type": "application/json",
      Accept: "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          
        
        })
        .catch((error) => {
          console.log(error)
        })
     
        
    } catch {
      
    }
    try {
     
      const graphqlQuery = {
        query: removePined,
        variables: {
          id: pin.pined[0].id,
        },
      };
      
      fetch(API, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.api_token,
      "Content-Type": "application/json",
      Accept: "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          window.location.reload();
        
        })
        .catch((error) => {
          console.log(error)
        })
     
        
    } catch {
      
    }

   
  };

 const savePin = (id)=>{
  try {
     
    const graphqlQuery = {
      query: savepost,
      variables: {
        author_id:parseInt(user.id),
        post_id:parseInt(id),
      },
    };
    
    fetch(API, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.cncToken,
    "Content-Type": "application/json",
    Accept: "application/json",
      },
      body: JSON.stringify(graphqlQuery),
    })
      .then(async (result) => {
        const res = await result.json();
        return res;
      })
      .then((response) => {
        
      
      })
      .catch((error) => {
        console.log(error)
      })
   
      
  } catch {
    
  }
 }
 
const svg = '<svg id="euM43TQnROA1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 841.89 595.28" shape-rendering="geometricPrecision" text-rendering="geometricPrecision"><rect id="euM43TQnROA2" width="553.3" height="92.88" rx="0" ry="0" transform="translate(873.82638 223.849343)" fill="#f3d517"/><rect id="euM43TQnROA3" width="535.54" height="92.88" rx="0" ry="0" transform="translate(-574.076875 23.010002)" fill="#211e1e"/><rect id="euM43TQnROA4" width="535.54" height="92.88" rx="0" ry="0" transform="translate(-574.076875 23.010002)" fill="#211e1e"/><rect id="euM43TQnROA5" width="535.54" height="92.88" rx="0" ry="0" transform="translate(-574.866337 424.68999)" fill="#211e1e"/>'

  return (
    <a href={`/pin-detail/${pin.id}`}>
    <div className="m-2" key={key}>
      <div
        onMouseEnter={() => setPostHovered(true)}
        onMouseLeave={() => setPostHovered(false)}
        className=" relative cursor-zoom-in w-auto hover:shadow-lg rounded-lg overflow-hidden transition-all duration-500 ease-in-out"
        
      >
        <div className="rounded-lg w-full "   >
        <LazyLoadImage
      alt={pin.title}
      src={Storage+pin.img.replace('public','')} // use normal <img> attributes as props
      hight="300"
      width="250"
      placeholderSrc= {Pinholder}	
      />
      </div>
        {postHovered && (
          <div
            className="absolute top-0 w-full h-full flex flex-col justify-between p-1 pr-2 pt-2 pb-2 z-50"
            style={{ height: '100%' }}
          >
            <div className="flex items-center justify-between">
              <div className="flex gap-2">
                <a
                 href={`/pin-detail/${pin.id}`}
                  className="bg-white w-9 h-9 p-2 rounded-full flex items-center justify-center text-dark text-xl opacity-75 hover:opacity-100 hover:shadow-md outline-none"
                ><MdDownloadForOffline />
                </a>
              </div>
              {saved ? (
                <button type="button" className="bg-yellow-500 opacity-70 hover:opacity-100 text-white font-bold px-5 py-1 text-base rounded-3xl hover:shadow-md outline-none">
                  {pin?.save?.length}  Saved
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    savePin(pin.id);
                  }}
                  type="button"
                  className="bg-yellow-500 opacity-70 hover:opacity-100 text-white font-bold px-5 py-1 text-base rounded-3xl hover:shadow-md outline-none"
                >
                  {pin?.save?.length}   {savingPost ? 'Saving' : 'Save'}
                </button>
              )}
            </div>
            <div className=" flex justify-between items-center gap-2 w-full">
              {pin.title?.slice(0,20).length > 0 ? (
                <a
                href={`/pin-detail/${pin.id}`}
                  className="bg-white flex items-center gap-2 text-black font-bold p-2 pl-4 pr-4 rounded-full opacity-70 hover:opacity-100 hover:shadow-md"
                 
                >
                  {' '}
                  <BsFillArrowUpRightCircleFill />
                  {pin.title?.slice(0,8)}...
                </a>
              ) : undefined}
              {
           pin.author?.id === user?.id && (
           <button
             type="button"
             onClick={(e) => {
               e.stopPropagation();
               deletePin(pin.id);
             }}
             className="bg-white p-2 rounded-full w-8 h-8 flex items-center justify-center text-dark opacity-75 hover:opacity-100 outline-none"
           >
             <AiTwotoneDelete />
           </button>
           )
        }
            </div>
          </div>
        )}
      </div>
      <a href={`/pin-detail/${pin.id}`} className="flex gap-2 mt-2 items-center">
       
        <p className="text-sm font-semibold text-black capitalize">{pin.title?.slice(0,26)}...</p>
      </a>
    </div>
    </a>
  );
};

export default Pin;
