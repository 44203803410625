import React from 'react'

const Paginate = ({hasMore, page,setPage}) => {
  return (
    <div>
  <div className="flex justify-center">
    <nav aria-label="Page navigation example">
      <ul className="flex list-style-none">
        <li className={page === 1 ? "page-item hidden": "page-item " }><a className="page-link text-xl py-3 px-6 relative block border-0 bg-transparent outline-none transition-all duration-300 rounded-md text-gray-800 hover:text-gray-800 hover:bg-yellow-300 focus:shadow-none" onClick={(e)=> setPage(page-1)} >Previous </a></li>
        <li className={hasMore ? "page-item disabled": "page-item hidden " }><a className="page-link text-xl py-3 px-6 relative block border-0 bg-transparent outline-none transition-all duration-300 rounded-md text-gray-800 hover:text-gray-800 hover:bg-yellow-300 focus:shadow-none" onClick={(e)=> setPage(page+1)} >Next</a></li>
      </ul>
    </nav>
  </div>
  <br />
 
</div>
  )
}

export default Paginate