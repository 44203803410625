import { createSlice } from "@reduxjs/toolkit";

export const catsSlice = createSlice({
  name: "Categories",
  initialState: {
    cats: null,
    pending: false,
    error: false,
  },
  reducers: {
    loadStart: (state) => {
      state.pending = true;
    },
    loadSuccess: (state, action) => {
      state.pending = false;
      state.cats = action.payload;
    },
    loadFailure: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});

export const { loadStart, loadSuccess, loadFailure } = catsSlice.actions;

export default catsSlice.reducer;
