export const API = process.env.REACT_APP_API;
export const Storage = process.env.REACT_APP_STORAGE;
export async function fetcher(body) {
  const data = await fetch(API, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  return data.json();
}
export const Cats = `
query Categories {
    categories{
      data{
        id
        name
        posts{
          id
        title
        content
        img
        }
      }
    }
    
  }
`;
export const Category = `
query category($id: String){
  category(id:$id){
    name
    posts{
      id
      title
      content
      img
      file
      author{
          name
        }
    }
  }
}
`;

export const Post = `
query post($id: String){
  post(id:$id){
    title
    content
    img
    file
    author{
      id
      name
      uimg
    }
    category{
      name
      posts{
        id
      title
    content
    img
    file
      }
    }
    comments{
      id
      reply
      user{
        id
        name
        uimg
      }
      
    }
    downloaded{
      id
    }
    
    pined{
      id
    }
  }
}
`;
export const Posts = `
query posts( $page: Int){
  posts(first: 30, page: $page) {
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
    data {
      id
      title
      content
      img
      file
      author{
          id
          name
          uimg
        }
      category{
        id
      }
      comments{
        id
        reply
      }
      pined{
        id
      }
    }
  }
}

`;
export const PSearch = `
  query Search($key: String, $page: Int){
    Search(key: $key first:10 page:$page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        title
        content
        img
        file
        author{
          name
        }
        
      }
    }
  }
`;
export const profile = `
query user($id: String) {
  user(id:$id){
    id
    name
    status
    email
    uimg
    posts{
      id
      title
      img
      content
      file
      author{
        id
        name
      }
      category{
        id
        name
      }
    }
   pined{
    id
    post{
      id
      title
      img
      content
      file
      author{
        id
        name
      }
      category{
        id
        name
      }
    }
  } 
}
}
`;

export const log = `
mutation login($email: String, $password: String){
  login(email:$email password:$password){
    id
    name
    status
    email
    api_token
    googleId
    uimg
  }
}
`;
export const createCategory = `
mutation ($name: String!){
  createCategory(name:$name){
    id
    name
  }
}
`;
export const removePost = `
mutation ($id: ID!){
  deletePost(id: $id){
    id
  }
}
`;
export const email = `
mutation(email:String, title:String, subgect:String){
  email(email:$email title:$title subgect:$subgect )
} `;
export const removePined = `
mutation ($id: ID!){
  deletePined(post_id:$id){
    id
  }
}
`;
export const createPost = `
mutation ($title: String!,$content: String!,$author_id: Int, $category_id: Int ){
  createPost(title:$title content:$content author_id:$author_id category_id:$category_id){
    id
    title
    content
    author{
      name
    }
    category{name}
  }
}
`;
export const social = `
mutation social($name: String, $email: String, $googleId: String, $uimg: String){
  social(name: $name email: $email googleId: $googleId uimg: $uimg)
}
`;
export const savepost = `
mutation createPined($author_id:Int $post_id:Int){
  createPined(author_id:$author_id post_id:$post_id){
    id
  }
}
`;
export const createcomment = `
mutation createComment($reply:String $author_id:Int $post_id:Int){
  createComment(reply:$reply author_id:$author_id post_id:$post_id){
    reply
    user{
      id
      name
      uimg
    }
  }
}

`;
export const downloadpost = `
mutation ccreateDownloaded($author_id:Int $post_id:Int){
  createDownloaded(author_id:$author_id post_id:$post_id){
    id
  }
}
`;
export async function getCategory(page, limit, search) {
  const getSearchBody = {
    query: `query ($key:Int, $limit:Int, $page:Int){
 
      CatSearch(key:$key first:$limit page:$page){
        paginatorInfo{
          currentPage
          hasMorePages
          total 
        }
        data{
          id
          title
          content
          img
          file
          author{
            name
          }
        }
      }
    }`,
    variables: {
      key: search || "",
      limit: parseInt(limit) || 5,
      page: parseInt(page) || 1,
    },
  };
  
  const res = await fetcher(getSearchBody);
  return{products: res.data.CSearch.data, total: res.data.CSearch.paginatorInfo.total, paginatorInfo: res.data.CSearch.paginatorInfo};
  
}