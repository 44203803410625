import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import logo from '../assets/logo.png';
import { Navbar, Feed, PinDetail, CreatePin, Search, Terms, Privacy } from '../components';
import Contact from '../components/Contact';
import Headtag from '../components/Headtag';

const Pins = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(2);
  const user = useSelector((state) => state.user.userInfo);

  return (
    <>
    <Headtag
        title="CNCMOTIFS vector free download AND cnc files free download"
        description="Download Free vector Art For Cnc And Laser Cut Shape And Pattern Decoration  - free design vector ( eps . cdr . dxf . pdf ), cnc vector,cnc decoration,file cncdxf cnc,cnc designs,cnc dxf,3 axis cnc dxf,arabic cnc,cnc design,cnc dxf files download,cnc file dxf,cncfile"
        keywords="free design vector ( eps . cdr . dxf . pdf ),cnc vector,cnc decoration,file cncdxf cnc,cnc designs,cnc dxf,3 axis cnc dxf,arabic cnc,cnc design,cnc dxf files download,cnc file dxf,cncfile"
        imageUrl={logo}
        imageAlt="CNCMOTIFS vector free download AND cnc files free download"
       />
    <div className="px-2 md:px-5">
      <div className="bg-gray-50">
        <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>
      </div>
      <div className="h-full">
        <Routes>
          <Route path="/" element={<Feed />} />
          <Route path="/category/:categoryId" element={<Feed />} />
          <Route path="/pin-detail/:pinId" element={<PinDetail user={user} />} />
          <Route path="/create-pin" element={<CreatePin user={user} />} />
          <Route path="/search" element={<Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} page={page} setPage={setPage} />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Contact" element={<Contact />} />

        </Routes>
      </div>
    </div></>
  );
};

export default Pins;
