
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Spinner from './Spinner';
import {API, createPost, createCategory, email} from '../utils/constants';
import { createSelectorHook, useDispatch,useSelector } from 'react-redux';

const Contact = () => {

  const [loading, setLoading] = useState(false);
  const [OK, setOK] = useState(false);
  const [NOK, setNOK] = useState(false);

 
  
  // form validation rules 
  const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Title is required'),
    email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
    subject: Yup.string()
        .required('Title is required'),    

});
const formOptions = { resolver: yupResolver(validationSchema) };

// get functions to build form with useForm() hook
const { register, handleSubmit, reset, formState } = useForm(formOptions);
const { errors } = formState;

function onSubmit(data) {
    // display form data on success
   // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
    console.log(data)
    
    try {
      setLoading(true)
      const graphqlQuery = {
        query: email,
        variables: {
          email: data.email,
          title: data.title,
          subject: data.subject,
        },
      };
      
      fetch(API, {
        method: "POST",
        headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let data = response;
          setOK(true)
          setLoading(false)
        
        })
        .catch((error) => {
          console.log(error)
          setNOK(true)
          setLoading(false)
        })
     
        
    } catch {
      setLoading(false)    
    }
    return false;
}

 
 
  
  
  return (
    <div>
      <div className="container">
    {loading ?  <Spinner />
   :
   <form onSubmit={handleSubmit(onSubmit)}>
    <div className=" flex lg:flex-row flex-col justify-center items-center bg-white lg:p-5 p-3 lg:w-4/5  w-full">
      
      <div className="flex flex-1 flex-col gap-6 lg:pl-5 mt-5 w-full">
      <h1 className="outline-none text-2xl sm:text-3xl font-bold border-b-2 border-gray-200 p-2 text-center"> Contact Us </h1>
         
        <input
          type="text"
          {...register('name')}
          placeholder="Name"
          className="outline-none text-2xl sm:text-3xl font-bold border-b-2 border-gray-200 p-2"
        />
          <p class="mt-2 text-sm text-red-600 dark:text-red-500"><span class="font-medium"></span> {errors.name?.message}</p>

      <input
          type="text"
          
          {...register('email')}
          placeholder="Email"
          className="outline-none text-2xl sm:text-3xl font-bold border-b-2 border-gray-200 p-2"
        />
         <p class="mt-2 text-sm text-red-600 dark:text-red-500"><span class="font-medium"></span> {errors.email?.message}</p>
       <textarea
          type="text"
          {...register('subject')}
          placeholder="Help us understand how we can help you"
          className="outline-none text-2xl sm:text-3xl font-bold border-b-2 border-gray-200 p-2"
        />
         <p class="mt-2 text-sm text-red-600 dark:text-red-500"><span class="font-medium"></span> {errors.subject?.message}</p>
        <div className="flex flex-col">
         
         
          <div className="flex justify-end items-end mt-5">
            <button
              type="Submit"
              className="bg-yellow-500 text-white font-bold p-2 rounded-full w-28 outline-none"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>  
    </form>}
  </div>
  {OK ?<div className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
  <span className="font-medium">Success !</span> Message sent.
</div>:null
}
{NOK ? <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
  <span className="font-medium"></span> Change a few things up and try submitting again.
</div>: null

}
  </div>
  );

};

export default Contact;
