import React, { useState } from 'react';
import GoogleLogin from 'react-google-login';
import { useNavigate } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';

import logo from '../assets/logowhite.png';
import {API, log} from '../utils/constants'
import { updateSuccess } from "../redux/userSlice";
import { updateUser } from "../redux/apiCalls";
import { useDispatch, useSelector } from 'react-redux';
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userInfo);
  const [email, setEmail] = useState("");
  const [psw, setPsw] = useState("");
  const [errors, setErrors] = useState(false);
  const [loading, setLoding] = useState(false);

  const responseGoogle = (response) => {
    let user= JSON.stringify(response.profileObj);
    updateUser( JSON.parse(user), dispatch );
   
 };
  


const on = ()=>{
 
 try {
  setLoding(true)
  const graphqlQuery = {
    query: log,
    variables: {
      email: email,
      password: psw,
    },
  };
  
  fetch(API, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(graphqlQuery),
  })
    .then(async (result) => {
      const res = await result.json();
      return res;
    })
    .then((response) => {
      let data = response;
      
      const user= data.data.login;
      sessionStorage.setItem('cncstorage', JSON.stringify(user))
      dispatch(updateSuccess(user))
     navigate("/")
    
    })
    .catch((error) => {
      console.log(error)
    })
 
    
} catch (error) {
   setErrors(true);
   console.log(error)
 }
}
  return (
    <div className="flex justify-start items-center flex-col h-screen">
      <div className=" relative w-full h-full">
       
        <div className="absolute flex flex-col justify-center items-center top-0 right-0 left-0 bottom-0    bg-blackOverlay">
        <section className="h-screen">
  <div className="container px-6 py-12 h-full">
    <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
      <div className="md:w-8/12 lg:w-6/12 mb-12 md:mb-0">
        <img src={logo} className="w-full" alt="logo" />
      </div>
      <div className="md:w-8/12 lg:w-5/12 lg:ml-20">
        <form>
          {/* Email input */}
          <div className="mb-6">
            <input type="text" className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="Email address" onChange={(e)=>setEmail(e.target.value)} />
          </div>
          {/* Password input */}
          <div className="mb-6">
            <input type="password" className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="Password" onChange={(e)=>setPsw(e.target.value)}/>
          </div>
          
          {/* Submit button */}
          <button type="button" className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full" onClick={on}>
            Sign in
          </button>
          <div className="flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
            <p className="text-center font-semibold mx-4 mb-0 text-stone-100">OR AS A USER</p>
          </div>
         
            <GoogleLogin
              clientId={`${process.env.REACT_APP_GOOGLE_API_TOKEN}`}
              render={(renderProps) => (
                <button
                  type="button"
                  className="px-7 py-3  text-slate-800 font-medium text-sm leading-snug uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full flex justify-center items-center bg-slate-100"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <FcGoogle className="mr-4" /> Sign in with google
                </button>
              )}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy="single_host_origin"
            /> 
         
        </form>
      </div>
    </div>
  </div>
</section>
        </div>
      </div>
    </div>
  );
};

export default Login;
