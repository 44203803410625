import React, { useState, useEffect } from 'react';
import Himg from "../assets/Himg.png";
import { useParams } from 'react-router-dom';
import { API, Posts,Category, Storage } from '../utils/constants';
import Masonry from 'react-masonry-css';
import Spinner from './Spinner';
import Paginate from './Paginate';
import Pin from './Pin';
import Adds from './Adds';
import Headtag from './Headtag'
import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux';
import CatsPreview from './CatsPreview';
import { useSearchParams } from 'react-router-dom';
var paginationator = require('paginationator');
const breakpointColumnsObj = {
  default: 4,
  3000: 6,
  2000: 5,
  1200: 3,
  1000: 2,
  500: 2,
};

const Feed = () => {
  const [searchParams] = useSearchParams();
  //console.log(searchParams.get('sort'));
  const [pins, setPins] = useState([]);
  const [pinsC, setPinsC] = useState([]);
  const [loading, setLoading] = useState(false);
  const { categoryId } = useParams();
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const categories = useSelector((state) => state.Categories.cats);
  const user = useSelector((state) => state.user.userInfo);
  const [Pages, setPages] = useState({});
  const [category, setCategory] = useState([]);
  const [limit, setLimit] = useState(0);

  function getRandom(arr, n) {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
    
}


  useEffect(() => {
    fetchImages(page,categoryId);
    
    
  }, [categoryId,page]);
  const fetchImages = (pp,id) => {
    let currentP = pp ;
    
    if(id){
      setLoading(true)
      setPinsC([]);
      if(categories){ 
     pinsC.push(categories.filter(cat => cat.id === id)[0]);
     var pages = paginationator(pinsC[0].posts, {limit: 20});
     const index = page - 1;
     if(pages.pages[index]=== undefined){
      setPage(1)      
      }
      else{
      setPins(pages.pages[index].items)
      setHasMore(pages.pages[index].hasNext); 
    }
    }else{
      setLoading(true);
      
    }
     
      setLoading(false)   
    }else{try {
      setLoading(true)
      setLimit(0);
      const graphqlQuery = {
        query: Posts,
        variables: {
          page: currentP,
        },
      };
      
      fetch(API, {
        method: "POST",
        headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
        },
        body: JSON.stringify(graphqlQuery),
      })
        .then(async (result) => {
          const res = await result.json();
          return res;
        })
        .then((response) => {
          let res = response;
            setPins(res.data.posts.data);
            setHasMore(res.data.posts.paginatorInfo.hasMorePages)
            setPage(res.data.posts.paginatorInfo.currentPage)
            setLoading(false);
            
        
        })
        .catch((error) => {
          console.log(error)
        })
     
        
    } catch {
      setLoading(false)    
    }}  
  };
 
  const ideaName =  'new';
  if (loading) {
    return (
      <Spinner message={`We are adding ${ideaName} ideas to your feed!`} />
    );
  }
  return (
    
    <div>
      <Headtag
        title={category.name}
        description="Download Free vector Art For Cnc And Laser Cut Shape And Pattern Decoration  - free design vector ( eps . cdr . dxf . pdf ), cnc vector,cnc decoration,file cncdxf cnc,cnc designs,cnc dxf,3 axis cnc dxf,arabic cnc,cnc design,cnc dxf files download,cnc file dxf,cncfile"
        keywords="free design vector ( eps . cdr . dxf . pdf ),cnc vector,cnc decoration,file cncdxf cnc,cnc designs,cnc dxf,3 axis cnc dxf,arabic cnc,cnc design,cnc dxf files download,cnc file dxf,cncfile"
        imageUrl={Himg}
        
      />
      {!categoryId ?(<>
      <div className=" m-2 flex flex-col justify-center">
          <h1 className="text-center text-2xl font-bold">BEST CATEGORIES</h1>
       
        </div>
      <Masonry className="flex flex-row animate-slide-fwd" breakpointCols={breakpointColumnsObj}>
        
    {categories?.map((category) => (
      <div className="m-2" >
        
    <div className=" mx-auto sm:max-w-xl md:mx-auto lg:mx-auto ">
 
        <h2 className="max-w-lg mb-6 text-center text-lg font-bold leading-none tracking-tight text-gray-900 sm:text-base md:mx-auto">
     {category.name}
        </h2>
        {category.posts.length > 3 ? getRandom(category.posts,3).map((pin) => (
          <div className="flex flex-col w-48 mt-1 ">
  <li href="#"  className="flex-col justify-center h-20 py-2 px-4 w-full text-white  rounded-t-lg border-b border-gray-200 cursor-pointer ">
  <CatsPreview pin={pin} />
  </li>
</div>

        
    
      )) : null}
      
    </div>
    </div>
    )
  )}
  
 
  </Masonry>
  <div class="relative flex py-5 items-center">
    <div class="flex-grow border-t border-gray-400"></div>
    <span class="flex-shrink mx-4 text-gray-400">Content</span>
    <div class="flex-grow border-t border-gray-400"></div>
</div>
</>): <></>}
      
       {pins && (<>
        <Masonry className="flex animate-slide-fwd" breakpointCols={breakpointColumnsObj}>
          
        {pins?.map((pin, i) =>{
         if(i%8===1){
          return (
            
      <><Adds /></>
       );
     } return(<Pin key={uuidv4()} pin={pin} className="w-max" />)})
     }
         
      
     </Masonry>
     
         {limit === 0 ? <Paginate page={page} setPage={setPage} hasMore={hasMore} /> : <div>
  <div className="flex justify-center">
    <nav aria-label="Page navigation example">
      <ul className="flex list-style-none">
        <li className={page === 1 ? "page-item hidden": "page-item " }><a className="page-link text-xl py-3 px-6 relative block border-0 bg-transparent outline-none transition-all duration-300 rounded-md text-gray-800 hover:text-gray-800 hover:bg-yellow-300 focus:shadow-none" onClick={(e)=> {setPage(page-1) }} >Previous </a></li>
        <li className={page != limit ? "page-item disabled": "page-item hidden " }><a className="page-link text-xl py-3 px-6 relative block border-0 bg-transparent outline-none transition-all duration-300 rounded-md text-gray-800 hover:text-gray-800 hover:bg-yellow-300 focus:shadow-none" onClick={(e)=> {setPage(page+1)}} >Next</a></li>
      </ul>
    </nav>
  </div>
  <br />
 
</div> };
         

        

        </>)
        
        } 
  
    </div>
  );
};

export default Feed;
