import { updateStart, updateSuccess, updateFailure } from "./userSlice";
import { loadStart, loadSuccess, loadFailure } from "./catsSlice";
import { API, social, Cats, profile } from "../utils/constants";

const getuser = (id) => {
  const userDetail = [];
  try {
    const graphqlQuery = {
      query: profile,
      variables: {
        id: id,
      },
    };

    fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(graphqlQuery),
    })
      .then(async (result) => {
        const res = await result.json();
        return res;
      })
      .then((response) => {
        let res = response;
        userDetail.push(res.data.user);

        return userDetail;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch {}
};
export const updateUser = (user, dispatch) => {
  dispatch(updateStart());
  try {
    const graphqlQuery = {
      query: social,
      variables: {
        name: user.name,
        email: user.email,
        googleId: user.jti || user.googleId,
        uimg: user.picture || user.imageUrl,
      },
    };

    fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(graphqlQuery),
    })
      .then(async (result) => {
        const res = await result.json();
        return res;
      })
      .then((response) => {
        let data = response;
        const dd = JSON.stringify(data);
        const dd1 = JSON.parse(dd);
        const dd2 = JSON.parse(dd1.data.social);
        const tt = dd2[0].id;
        return tt;
      })
      .then((req) => {
        let tt = req;
        const userDetail = [];
        try {
          const graphqlQuery = {
            query: profile,
            variables: {
              id: tt.toString(),
            },
          };

          fetch(API, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(graphqlQuery),
          })
            .then(async (result) => {
              const res = await result.json();
              return res;
            })
            .then((response) => {
              let res = response;
              let userDetail = res.data.user;
              sessionStorage.setItem("cncstorage", JSON.stringify(userDetail));
              sessionStorage.setItem("cncstorage", JSON.stringify(userDetail));
              dispatch(updateSuccess(userDetail));
            })
            .catch((error) => {
              console.log(error);
            });
        } catch {}
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateFailure());
      });
  } catch (err) {
    dispatch(updateFailure());
  }
};

export const loadCats = (dispatch) => {
  dispatch(loadStart());
  try {
    const graphqlQuery = {
      query: Cats,
    };

    fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(graphqlQuery),
    })
      .then(async (result) => {
        const res = await result.json();
        return res;
      })
      .then((response) => {
        let data = response;
        dispatch(loadSuccess(data.data.categories.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(loadFailure());
      });
  } catch (err) {
    dispatch(loadFailure());
  }
};
