import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Login, Terms, Privacy } from "./components";
import Home from "./container/Home";
import { updateUser, loadCats } from "./redux/apiCalls";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import { updateSuccess } from "./redux/userSlice";
import Headtag from "./components/Headtag";

const App = () => {
  const dispatch = useDispatch();
  const onOneTapSignedIn = (response) => {
    let decodedToken = jwt_decode(response.credential);
    //sessionStorage.setItem("cncstorage", JSON.stringify(decodedToken));
    //const user = sessionStorage.getItem("cncstorage");
    updateUser(decodedToken, dispatch);
  };

  const initializeGSI = () => {
    // eslint-disable-next-line no-undef
    google.accounts.id.initialize({
      client_id: `${process.env.REACT_APP_GOOGLE_API_TOKEN}`,

      cancel_on_tap_outside: false,
      callback: onOneTapSignedIn,
    });
    // eslint-disable-next-line no-undef
    google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed()) {
        console.log(notification.getNotDisplayedReason());
      } else if (notification.isSkippedMoment()) {
        console.log(notification.getSkippedReason());
      } else if (notification.isDismissedMoment()) {
        console.log(notification.getDismissedReason());
      }
    });
  };
  useEffect(() => {
    loadCats(dispatch);
  });
  useEffect(() => {
    const ls = sessionStorage.getItem("cncstorage");
    ls !== "undefined"
      ? dispatch(updateSuccess(JSON.parse(ls)))
      : localStorage.clear();
    if (!ls) {
      const el = document.createElement("script");
      el.setAttribute("src", "https://accounts.google.com/gsi/client");
      el.onload = () => initializeGSI();
      document.querySelector("body").appendChild(el);
    }
  });
  return (
    <>
      <Headtag
        title="CNCMOTIFS Home"
        description="Download Free vector Art For Cnc And Laser Cut Shape And Pattern Decoration  - free design vector ( eps . cdr . dxf . pdf ), vector laser, vector art, vector graphic , file corel, file illustrator, file design, vector design , free dxf files with cuttable designs cut ready for cnc machines plasma,laser, router, waterjet. diy for wall art, home decor,3d, sign, silhouette, 3d puzzle, plasma cutter, wood cutting and engraving, cnc, file,"
        keywords="free design vector ( eps . cdr . dxf . pdf ), vector laser, vector art, vector graphic , file corel, file illustrator, file design, vector design , free dxf files with cuttable designs cut ready for cnc machines plasma,laser, router, waterjet. diy for wall art, home decor,3d, sign, silhouette, 3d puzzle, plasma cutter, wood cutting and engraving, cnc, file,"
      />

      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/*" element={<Home />} />
      </Routes>
    </>
  );
};

export default App;
