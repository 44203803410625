import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import { IoMdAdd, IoMdSearch } from 'react-icons/io';
import { FcGoogle } from 'react-icons/fc';
import uimg from '../assets/user.jpg';
import { useSelector ,useDispatch } from "react-redux";
import { updateUser } from "../redux/apiCalls";

const Navbar = ({ searchTerm, setSearchTerm }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();
  const responseGoogle = (response) => {
    const mt = sessionStorage.setItem('cncstorage', JSON.stringify(response.profileObj));
    const user= sessionStorage.getItem('cncstorage', JSON.stringify(response.profileObj));
   updateUser( JSON.parse(user), dispatch );
    
  };

    return (
      <div className="flex gap-2 md:gap-5 w-full mt-5 pb-7 ">
        <div className="flex justify-start items-center w-full px-2 rounded-md bg-white border-none outline-none focus-within:shadow-sm">
          <IoMdSearch fontSize={21} className="ml-1" />
          <input
            type="text"
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search"
            value={searchTerm}
            onFocus={() => navigate('/search')}
            className="p-2 w-full bg-white outline-none"
          />
        </div>
        <div className="flex gap-3 ">
        {!user ? <div className="hidden md:block shadow-2xl ">
            <GoogleLogin
              clientId={`${process.env.REACT_APP_GOOGLE_API_TOKEN}`}
              render={(renderProps) => (
                <button
                  type="button"
                  className="h-12 bg-mainColor flex justify-center items-center p-3 rounded-lg cursor-pointer outline-none hover:bg-yellow-300"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <FcGoogle className="mr-4" /><h2 className="font-bold">login</h2> 
                </button>
              )}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy="single_host_origin"
            />
          </div > : <div className="flex gap-1 "><Link to={`user-profile/${user?.id}`} className="hidden md:block">
            <img src={user.uimg} alt="user-pic" className="w-14 h-12 rounded-lg " />
          </Link>{user.status === "admin" ?<Link to="/create-pin" className="bg-black text-white rounded-lg w-12 h-12 md:w-14 md:h-12 flex justify-center items-center">
            <IoMdAdd />
          </Link> : <div></div>} </div>}
          
          
        </div>
      </div>
    );
  

  return null;
};

export default Navbar;
