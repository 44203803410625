import React from 'react';
import Masonry from 'react-masonry-css';
import Pin from './Pin';

const breakpointColumnsObj = {
  default: 4,
  3000: 6,
  2000: 5,
  1200: 3,
  1000: 2,
  500: 1,
};
const goToTop = () => {
  window.scrollTo(0, 0);
  window.location.reload();
};
const MasonryLayout = ({ pins,saved }) => (
  <Masonry className="flex animate-slide-fwd" breakpointCols={breakpointColumnsObj}>
    {pins.map((pin) => <Pin saved={saved} key={pin.id} pin={pin} className="w-max" goToTop={goToTop}/>)}
  </Masonry>
);

export default MasonryLayout;
