import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { RiHomeFill } from 'react-icons/ri';
import { IoIosArrowForward } from 'react-icons/io';
import logo from '../assets/logo.png';
import { useSelector } from 'react-redux';

const isNotActiveStyle = 'flex items-center px-5 gap-3 text-gray-500 hover:text-black transition-all duration-200 ease-in-out capitalize';
const isActiveStyle = 'flex items-center px-5 gap-3 font-extrabold border-r-2 border-black  transition-all duration-200 ease-in-out capitalize';

const Sidebar = ({ closeToggle}) => {
  const user = useSelector((state) => state.user.userInfo);
  const categories = useSelector((state) => state.Categories.cats);
  
  const handleCloseSidebar = () => {
    if (closeToggle) closeToggle(false);
  };

  return (
    <div className="flex flex-col justify-between bg-white h-full overflow-y-scroll min-w-210 hide-scrollbar">
      <div className="flex flex-col">
        <Link
          to="/"
          className="flex px-5 gap-2 my-6 pt-1 w-190 items-center"
          onClick={handleCloseSidebar}
        >
          <img src={logo} alt="logo" className="w-full" />
        </Link>
        <div className="flex flex-col gap-5">

          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? isActiveStyle : isNotActiveStyle)}
            onClick={handleCloseSidebar}
          >
            <RiHomeFill />
            Home
          </NavLink>
          <h3 className="mt-2 px-5 text-base 2xl:text-xl">Discover cateogries</h3>
          {categories?.map((category) => (
            <NavLink
              to={`/category/${category.id}`}
              className={({ isActive }) => (isActive ? isActiveStyle : isNotActiveStyle)}
              onClick={handleCloseSidebar}
              key={category.name}
            >
              {category.name}
            </NavLink>
          ))}
          
        </div>
        
      </div>
      <div className="flex flex-col">
      {user ? (
        <Link
          to={`user-profile/${user.id}`}
          className="flex my-5 mb-3 gap-2 p-2 items-center bg-white rounded-lg shadow-lg mx-3"
          onClick={handleCloseSidebar}
        >
          <img src={user.uimg} className="w-10 h-10 rounded-full" alt="user-profile" />
          <p>{user.name}</p>
          <IoIosArrowForward />
        </Link>
      ) : <NavLink
      to="/login"
      className="flex my-5 mb-3 gap-2 p-2 items-center bg-yellow-300 rounded-lg shadow-lg mx-3"
      onClick={handleCloseSidebar}
    >
      
      Login
    </NavLink>}
    
    <NavLink
      to="/Terms"
      className={({ isActive }) => (isActive ? isActiveStyle : isNotActiveStyle)}
      onClick={handleCloseSidebar}
    > Terms
    </NavLink>
    <NavLink
      to="/Privacy"
      className={({ isActive }) => (isActive ? isActiveStyle : isNotActiveStyle)}
      onClick={handleCloseSidebar}
    >
     Privacy
    </NavLink> 
    <NavLink
      to="/Contact"
      className={({ isActive }) => (isActive ? isActiveStyle : isNotActiveStyle)}
      onClick={handleCloseSidebar}
    >
     Contact Us
    </NavLink> 
    </div>
    </div>
  );
};

export default Sidebar;
